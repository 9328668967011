import React from 'react';
import './MainContainer.css';

export default class MainContainer extends React.Component {
  render() {
    const { children } = this.props;
    return (
        <div className="main-container">
          {children}
        </div>
    );
  }
}
