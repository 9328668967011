import React, { Component } from "react";
import EnsembleList from './Ensemble/EnsembleList';
import Navbar from './Navbar/Navbar';
import Sidebar from './Sidebar/Sidebar';
import MainContainer from './MainContainer/MainContainer';
import EnsembleService from './Ensemble/EnsembleService'
import './App.css'
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      ensembles: [],
    }
  }

  async componentDidMount() {
    try {
      const ensembleService = new EnsembleService();
      const ensembles = await ensembleService.getEnsembles();
      this.setState({ loading: false, ensembles });
    } catch (error) {
      this.setState({ loading: false, error });
    }
  }

  render() {
    const { ensembles, loading, error } = this.state;
    
    return (
      <div className="all">
        <Navbar />
        <div className="container">
          <Sidebar  />
          <MainContainer>
            <EnsembleList 
            ensembles = {ensembles} 
            loading = {loading}
            error = {error} />
          </MainContainer>
        </div>
      </div>
    );
  }
}

