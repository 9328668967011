import React, { Component } from "react";
import EnsembleItem from "./EnsembleItem";
import "../css/Ensembles-container.css";

export default class EnsembleList extends Component {

  render() {
    const { loading, error, ensembles } = this.props;

    if (loading) {
      return <div className="ensembles-container">Loading ensembles...</div>;
    }

    if (error) {
      return <div className="ensembles-container">Error loading ensembles: {error.message}</div>;
    }

    return (
      <div className="ensembles-container">
        {ensembles.map(ensemble => (
          <EnsembleItem key={ensemble.id} ensemble={ensemble} />
        ))}
      </div>
    );
  }
}
