import Ensemble from './Ensemble'
export default class EnsembleService {
  async getEnsembles() {
    let response = await fetch('https://api.circuselysian.co.uk/ensembles/');
    let ensembles = await response.json();

    // Define the base URL for ensemble images
    const imageUrlBase = "/images/";

    // Loop through each ensemble and set the imageUrl property
    ensembles.forEach((ensemble) => {
      ensemble.imageUrl = `${imageUrlBase}${ensemble.name}.jpg`;
    });
    
    return ensembles.map(ensembleData => new Ensemble(ensembleData));
  }
}
