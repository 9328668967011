export default class Ensemble {
    constructor({ id, name, instructions, ingredients, imageUrl }) {
      this.id = id;
      this.name = name;
      this.instructions = instructions;
      this.ingredients = ingredients;
      this.imageUrl = imageUrl;
    }

    static getUniqueIngredients(ensembles) {
      const uniqueIngredients = Array.from(new Set(ensembles.flatMap(
        ensemble => ensemble.ingredients
      )));
      return uniqueIngredients;
    }
  }