import React from 'react';
import './Sidebar.css';


export default class Sidebar extends React.Component {

  render() {
    return (
      <div className='sidebar'>
        <div className="title">
          Circus Elysian
        </div>
        <div className="desc">
          Andre's Cocktail site.
        </div>
      </div>
    );
  }
}
