import React from "react";
import '../css/Ensemble.css';
import '../css/Instructions.css';

class EnsembleItem extends React.Component {

  render() {
    const { ensemble } = this.props;

    return (
      <div className='ensemble-item'>
        <div className="image">
          <img className="ensemble-image" src={ensemble.imageUrl} alt="Ensemble" />
        </div>
        <div className="info">
          <h2 id={ensemble.name} className="ensemble-title">{ensemble.name}</h2>
          <ul className="ensemble-ingredients">
            {ensemble.ingredients.map(({ name, quantity }) => (
              <li className="ingredient" key={name}>
                <span className="ingredient-name">{name}</span>
                <span className="ingredient-quantity">{quantity}</span>
              </li>
            ))}
          </ul>
          <div className="instructions">
            Instructions
          </div>
          <div className="ensemble-instructions">
            {ensemble.instructions}
          </div>
        </div>
      </div>
    );
  }
}

export default EnsembleItem;
